<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !evolutions.length" color="success" depressed size="small" @click="getPDF(false)"
      :loading="loading" :username="username">
      <v-icon left>mdi-printer</v-icon> Rel. Evoluções
    </v-btn>
    <v-btn :disabled="loading || !evolutions.length" color="success" depressed size="small" @click="getPDF(true)"
      :loading="loading" :username="username">
      <v-icon left>mdi-printer</v-icon> Rel. Evolução + Anexos
    </v-btn>
    <EvolucoesPorEspecialidade :evolutions="evolutions" :filtered-data="filteredData" :data_inicio="data_inicio"
      :data_fim="data_fim" :username="username">
    </EvolucoesPorEspecialidade>
  </v-container>
</template>

<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsService from '../../../service/utilsService';
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png";
import "../../../assets/css/styles.css";

const { withCRUDUtils } = UtilsFunc;
export default {
  name: "Evolucoes",
  components: {
    EvolucoesPorEspecialidade: () => import('./EvolucoesPorEspecialidade.vue'),
  },
  data: () =>
    withCRUDUtils({
      loading: false,
      quadroclinico: [],
      isFirstPage: true,
    }),
  props: {
    filteredData: {
      type: Array,
      default: () => []
    },
    evolutions: {
      type: Array,
      default: () => ({})
    },
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    data_inicio: {
      type: String,
      default: null
    },
    data_fim: {
      type: String,
      default: null
    },
    username: String
  },
  methods: {
    async getPDF(includeAttachments) {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await this.getQuadroClinico();
        await this.savePDF(includeAttachments)
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.error("Erro ao carregar os dados (L-76):", error);
        throw error
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF(includeAttachments) {
      const data = this.filteredData[0];
      this.paciente = data.paciente.nome;
      this.operadora = data.sessao.operadora;

      const name = this.paciente.replace(/\s+/g, '_');
      const empresa = this.operadora.nome.replace(/\s+/g, '_');
      const period = this.data_inicio && this.data_fim ? `${UtilsPDF.formatDateToFilename(this.data_inicio)}_ate_${UtilsPDF.formatDateToFilename(this.data_fim)}` : '';
      let filename;

      // includeAttachments ? filename = `Relatorio_Evolucoes_com_Anexos_${name}_${empresa}_Periodo_${period}.pdf`
      //   : filename = `Relatorio_Evolucoes_${name}_${empresa}_Periodo_${period}.pdf`;
        includeAttachments ? filename = `Evolucoes_${name}_${empresa}_${period}.pdf`
      : filename = `Evolucoes_${name}_${empresa}_${period}.pdf`;

      await this.generatePDF(includeAttachments);

      const pdfBuffer = this.doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);
      const signedPdfUrl = signedForm.path;
      this.download(signedPdfUrl, filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-121):", error);
      }

      const pageWidth = 210;
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text("Relatório Multidisciplinar", 105, 23, { align: "center" });

      moment.locale("pt-br");

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const xFixedPosition = 10;
      const yFixedPosition = 74;
      const lineHeight = 6

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"}); 
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right"});
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right"});

      if (this.data_inicio && this.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(this.data_inicio)} até: ${UtilsPDF.formatDate(this.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);

      const data = this.filteredData.filter((item) =>
        this.evolutions.includes(item.id)
      );

      this.paciente = data[0].paciente
      this.operadora = data[0].sessao.operadora
      const paciente = this.paciente


      let yPosition = 46
      doc.text(
        `Paciente: ${paciente && paciente ? paciente.nome : ''}`,
        xFixedPosition,
        yPosition
      );

      const dataNascimento = paciente && paciente.data_nascimento
        ? `Data Nascimento: ${UtilsPDF.formatDate(paciente.data_nascimento)}`
        : 'Data Nascimento: ';

      doc.text(
        dataNascimento,
        200,
        yPosition,
        { align: "right" }
      );

      yPosition += lineHeight
      doc.text(`Operadora: ${this.operadora.nome ?? ''}`, xFixedPosition, yPosition);

      doc.text(`Nº da Carteira: ${paciente && paciente.nr_carteirinha ? paciente.nr_carteirinha : ''}`, 200, yPosition, {
        align: "right",
      });

      yPosition += lineHeight

      const modalidade = data && data[0].modalidade ? data[0].modalidade : ''
      doc.text(
        `Modalidade: ${modalidade.nome}`,
        xFixedPosition,
        yPosition
      );

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data[0].modalidade ? data[0].modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      const cid = paciente && paciente.cid ? data[0].paciente.cid : ''

      if (cid.nome && cid.descricao) {
        const cidDescricao = cid.descricao || '';
        const fullText = `CID: ${cid.nome} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }


      yPosition += lineHeight * 4
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Registro de Evoluções");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Registro de Evoluções", xCenteredPosition, yFixedPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
    },
    async generatePDF(includeAttachments) {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const maxWidth = 190;
      const maxHeight = 265;
      const xFixedPosition = 10;
      const lineHeight = 6;
      const lineWidth = 3;

      const data = this.filteredData.filter((item) =>
        this.evolutions.includes(item.id)
      );

      // Ordena os dados pelo timestamp em ordem decrescente
      data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      let yPosition = 86;
      let xPosition = 10;
      const initialPosition = 86;
      this.contentAdded = false;

      // Parte do Quadro Clínico se houver
      const currentPage = doc.internal.getNumberOfPages();
      if (currentPage === 1) {
        const quadro = this.quadroclinico.quadro_clinico;
        if (quadro) {
          yPosition = 80;
          yPosition += lineHeight;
          doc.setFont("helvetica", "bold");
          let titulo = "Quadro Clínico: ";
          let tituloLargura = doc.getTextWidth(titulo);
          doc.text(titulo, xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          let maxLineWidth = 190;
          let conteudo = quadro;
          let linhas = doc.splitTextToSize(conteudo, maxLineWidth - tituloLargura);
          if (linhas.length > 1) {
            linhas.forEach((linha, index) => {
              if (index === 0) {
                xPosition = xFixedPosition + lineWidth + doc.getTextWidth(titulo);
              } else {
                xPosition = xFixedPosition;
              }
              doc.text(linha, xPosition, yPosition);
              if (yPosition >= 240) {
                yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 260, initialPosition)
              }
              yPosition += lineHeight;
            });
          } else {
            xPosition = xFixedPosition + lineWidth + doc.getTextWidth(titulo);
            doc.text(linhas, xPosition, yPosition);
          }
          yPosition += lineHeight;
        }
      }

      // yPosition = 98;
      for (let item of data) {
        xPosition = 10;
        doc.setFontSize(12);

        const dataEvolucao = moment(item.timestamp).format("DD/MM/YYYY");
        const horaInicio = moment(item.hora_inicio, "HH:mm:ss").format("HH:mm");
        const horaFim = moment(item.hora_fim, "HH:mm:ss").format("HH:mm");
        const especialidade = item?.profissional?.especialidade || item?.especialidade;

        if (yPosition >= 260) { // Se a evolução começar no fim da página, adiciona uma nova página
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 260, initialPosition)
        }

        doc.setFont("helvetica", "bold");
        doc.text(`Data da Evolução:`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`Data da Evolução:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${dataEvolucao}.`, xPosition, yPosition);

        doc.setFont("helvetica", "bold");
        xPosition += doc.getTextWidth(`${dataEvolucao}. `);

        doc.text(`Hora da Realização: `, xPosition, yPosition);
        xPosition += doc.getTextWidth(`. Hora da Realização:`);

        doc.setFont("helvetica", "normal");
        doc.text(`${horaInicio}`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`${horaInicio} `);

        doc.setFont("helvetica", "bold");
        doc.text(` até: `, xPosition, yPosition);
        xPosition += doc.getTextWidth(` até: `);

        doc.setFont("helvetica", "normal");
        doc.text(`${horaFim}.`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`${horaFim}.`) + lineWidth;

        doc.setFont("helvetica", "bold");
        doc.text(`ID da Evolução:`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`. ID da Evolução:`);
        doc.setFont("helvetica", "normal");
        doc.text(`${item.id}`, xPosition, yPosition);

        xPosition = 10;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);
        yPosition += lineHeight;

        doc.setFont("helvetica", "bold");
        doc.text(`Especialidade: `, xPosition, yPosition);
        xPosition += doc.getTextWidth(`Especialidade:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        if (especialidade && especialidade?.nome) {
          doc.text(`${especialidade?.nome}.`, xPosition, yPosition);
          xPosition += doc.getTextWidth(`${especialidade?.nome}.`) + lineWidth;
        }

        doc.setFont("helvetica", "bold");
        doc.text(`Procedimento: `, xPosition, yPosition);
        xPosition += doc.getTextWidth(`Procedimento: `) + lineWidth;

        const nomeProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? `${item.procedimento.procedimento} - (${item.procedimento.nomecomercial})` : item.procedimento.procedimento;
        doc.setFont("helvetica", "normal");
        doc.text(`${nomeProcedimento}.`, xPosition, yPosition);

        xPosition = 10;
        yPosition += lineHeight;

        if (item.profissional?.apelido) {
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);
          doc.setFont("helvetica", "bold");
          doc.text(`Profissional: `, xFixedPosition, yPosition);
          const profissionalLabelWidth = doc.getTextWidth(`Profissional: `);
          doc.setFont("helvetica", "normal");
          doc.text(
            `${item.profissional?.apelido}.`,
            xFixedPosition + profissionalLabelWidth,
            yPosition
          );

          const profissionalWidth =
            profissionalLabelWidth +
            doc.getTextWidth(`${item.profissional?.apelido}.`);
          xPosition += profissionalWidth + lineWidth;
        }

        if (item.profissional?.conselho && item.profissional?.conselho?.nome) {
          doc.setFont("helvetica", "bold");
          doc.text(`Conselho: `, xPosition, yPosition);
          const conselhoLabelWidth = doc.getTextWidth(`Conselho: `);

          doc.setFont("helvetica", "normal");
          doc.text(
            `${item.profissional?.conselho?.nome}.`,
            xPosition + conselhoLabelWidth,
            yPosition
          );

          xPosition +=
            conselhoLabelWidth +
            doc.getTextWidth(`${item.profissional?.conselho?.nome}.`) +
            lineWidth;

          doc.setFont("helvetica", "bold");
          doc.text(`Nº Conselho: `, xPosition, yPosition);

          xPosition += lineWidth;
          doc.setFont("helvetica", "normal");
          doc.text(
            `${item.profissional.nconselho}.`,
            xPosition + doc.getTextWidth(`Nº Conselho: `),
            yPosition
          );
        }

        xPosition = 10;
        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);
    // Peso e Altura
        if ( item?.peso || item?.altura) {
          doc.setFont("helvetica", "bold");
          doc.text("Altura:", xPosition, yPosition);
          doc.text(`${item.altura ? `${item.altura} kg` : 'Não cadastrado'} - Peso: ${item.peso ? `${item.peso} m` : 'Não cadastrado'} `, xPosition + 15, yPosition);
          yPosition += lineHeight;
        }
    // TQT
        if ( item?.tqt && (item?.profissional?.especialidade?.id === 4 || item?.profissional?.especialidade?.id === 3)) {
          doc.setFont("helvetica", "bold");
          doc.text("Possui TQT:", xPosition, yPosition);

          doc.setFont("helvetica", "normal");
          doc.text(`Modelo: ${item.tqt.modelo}, Número: ${item.numeracao_tqt}`, xPosition + 30, yPosition);
          yPosition += lineHeight; // Move para a próxima linha
        }
    // GTT
        if ( item?.via_alimentacao_gtt && (item?.profissional?.especialidade?.id === 4 || item?.profissional?.especialidade?.id === 3)) {
          doc.setFont("helvetica", "bold");
          doc.text("Possui Sonda / GTT:", xPosition, yPosition);

          doc.setFont("helvetica", "normal");
          doc.text(` Modelo: ${item?.via_alimentacao_gtt?.tipo ? item?.via_alimentacao_gtt?.tipo : 'Não informada'}; Tamanho: ${item?.tamanho_gtt ? item?.tamanho_gtt : 'Não informado'}`, xPosition + 42, yPosition); yPosition += lineHeight; // Move para a próxima linha
        }
    // Sinais Vitais
        if ((item?.profissional?.especialidade?.id === 4 || item?.profissional?.especialidade?.id === 3) && item?.sinais_vitais_pa) {
          doc.setFont("helvetica", "bold");
          doc.text("Sinais Vitais:", xPosition, yPosition);

          doc.setFont("helvetica", "normal");
          // Concatenando os sinais vitais em uma única linha
          let sinaisVitais = `
            PA: ${item?.sinais_vitais_pa ? item?.sinais_vitais_pa : '-'}, FC: ${item?.sinais_vitais_fc ? item?.sinais_vitais_fc : '-'},  FR: ${item?.sinais_vitais_fr ? item?.sinais_vitais_fr : '-'},  Temperatura: ${item?.sinais_vitais_temperatura ? item?.sinais_vitais_temperatura : '-'},  Saturação: ${item?.sinais_vitais_saturacao ? item?.sinais_vitais_saturacao : '-'},  Consciência: ${item?.sinais_vitais_nivel_conciencia?.tipo ? item?.sinais_vitais_nivel_conciencia?.tipo : '-'}`;
          
          // Removendo as quebras de linha desnecessárias e ajustando o espaçamento
          doc.text(sinaisVitais.replace(/\n/g, '').trim(), xPosition + 30, yPosition);
          yPosition += lineHeight; // Move para a próxima linha
        }
    // Respirador   
        if ( data[0]?.paciente.respirador === true && (especialidade.id === 1 ) ) {
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);
          
          // Determina se o respirador é contínuo ou intermitente
          let respirador = item.uso_respirador === 'CONTÍNUO' ? 'contínua' : 'intermitente';
          
          // Adiciona o texto "Uso do respirador de forma"
          doc.setFont("helvetica", "bold");
          let texto1 = `Faz uso do respirador de forma `;
          doc.text(texto1, xPosition, yPosition);

          // Adiciona o texto contínua/intermitente
          doc.setFont("helvetica", "bold");
          let texto2 = `${respirador}.`;
          let larguraTexto1 = doc.getTextWidth(texto1);
          doc.text(texto2, xPosition + larguraTexto1 , yPosition);

          if (item?.tipo_ventilador_mecanico?.tipo) {

            // Adiciona os dados do "Modelo" na mesma linha com um espaçamento mínimo
            
            doc.setFont("helvetica", "bold");
            let textoModelo = `Modelo:`;
            let larguraTexto2 = doc.getTextWidth(texto2);
            doc.text(textoModelo, xPosition + larguraTexto1 + larguraTexto2 + 18, yPosition); // Espaçamento de 15
            
            // Adiciona o valor do modelo com espaçamento adicional
            doc.setFont("helvetica", "normal");
            let modeloVentilador = item?.tipo_ventilador_mecanico?.tipo || 'Não relatado';
            let larguraTextoModelo = doc.getTextWidth(textoModelo);
            doc.text(modeloVentilador, xPosition + larguraTexto1 + larguraTexto2 + larguraTextoModelo + 20, yPosition); // Espaçamento de 20
          } 

          // Move para a próxima linha para adicionar o "Modo Ventilatório"
          yPosition += lineHeight;

          
          if (item?.modo_ventilatorio?.tipo) {
            // Adiciona "Modo Ventilatório" na linha debaixo
            let modoVentilatorio = item?.modo_ventilatorio?.tipo || 'Não relatado';
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            let textoModo = `Modo Ventilatório:`;
            doc.text(textoModo, xPosition, yPosition);

            // Adiciona o valor do modo ventilatório
            doc.setFont("helvetica", "normal");
            let larguraTextoModo = doc.getTextWidth(textoModo);
            doc.text(modoVentilatorio, xPosition + larguraTextoModo + 5, yPosition); // Espaçamento de 5

            // Adiciona as novas informações "VC", "Ipap", "Epap", "FR", e "TINS" na mesma linha
            let ventiladorVC = item.ventilador_vc || '';
            let ventiladorIpap = item.ventilador_ipap || '';
            let ventiladorEpap = item.ventilador_epap || '';
            let ventiladorFR = item.ventilador_fr || '';
            let ventiladorTins = item.ventilador_tins || '';

            // Continuando na mesma linha após "Modo Ventilatório"
            doc.setFont("helvetica", "bold");
            let textoVC = `- VC:`;
            let larguraModoVentilatorio = doc.getTextWidth(modoVentilatorio);
            doc.text(textoVC, xPosition + larguraTextoModo + larguraModoVentilatorio + 8, yPosition); // Espaçamento de 10

            doc.setFont("helvetica", "normal");
            doc.text(`${ventiladorVC}ml`, xPosition + larguraTextoModo + larguraModoVentilatorio + doc.getTextWidth(textoVC) + 10, yPosition); // Espaçamento de 15

            doc.setFont("helvetica", "bold");
            let textoIpap = `- Ipap:`;
            let larguraVC = doc.getTextWidth(ventiladorVC);
            doc.text(textoIpap, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + doc.getTextWidth(textoVC) + 18, yPosition);

            doc.setFont("helvetica", "normal");
            doc.text(`${ventiladorIpap}cmH2O`, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + 20, yPosition);

            doc.setFont("helvetica", "bold");
            let textoEpap = `- Epap: `;
            let larguraIpap = doc.getTextWidth(ventiladorIpap);
            doc.text(textoEpap, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + 36, yPosition);

            doc.setFont("helvetica", "normal");
            doc.text(`${ventiladorEpap}cmH2O`, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + doc.getTextWidth(textoEpap) + 38, yPosition);

            doc.setFont("helvetica", "bold");
            let textoFR = `- FR: `;
            let larguraEpap = doc.getTextWidth(ventiladorEpap);
            doc.text(textoFR, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + larguraEpap + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + doc.getTextWidth(textoEpap) + 53, yPosition);

            doc.setFont("helvetica", "normal");
            doc.text(`${ventiladorFR}rmp`, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + larguraEpap + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + doc.getTextWidth(textoEpap) + doc.getTextWidth(textoFR) + 54, yPosition);

            doc.setFont("helvetica", "bold");
            let textoTINS = `- TINS: `;
            let larguraFR = doc.getTextWidth(ventiladorFR);
            doc.text(textoTINS, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + larguraEpap + larguraFR + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + doc.getTextWidth(textoEpap) + doc.getTextWidth(textoFR) + 62, yPosition);

            doc.setFont("helvetica", "normal");
            doc.text(`${ventiladorTins}s`, xPosition + larguraTextoModo + larguraModoVentilatorio + larguraVC + larguraIpap + larguraEpap + larguraFR + doc.getTextWidth(textoVC) + doc.getTextWidth(textoIpap) + doc.getTextWidth(textoEpap) + doc.getTextWidth(textoFR) + doc.getTextWidth(textoTINS) + 64, yPosition);

            // Avança para a próxima linha após imprimir tudo
            yPosition += lineHeight;
          }
        }


        if (item.texto_evolucao || item.texto_meta || item.diagnostico_clinico !== null || item.conduta !== null || item.exame_fisico !== null) {
          const nonVariableParts = [
            "Evolução: ",
            "Diagnóstico Clínico: ",
            "Conduta: ",
            "Exame Físico: ",
            "Objetivo Esperado: ",
          ];
          const variableParts = [
            `${item.texto_evolucao}.`,
            `${item.texto_meta}.`,
            `${item.diagnostico_clinico}.`,
            `${item.conduta}.`,
            `${item.exame_fisico}.`,
          ];

          let fullText = `Evolução: ${item.texto_evolucao}. `;
          if (item.diagnostico_clinico !== null) {
            fullText += `\nDiagnóstico Clínico: ${item.diagnostico_clinico}.`;
          }
          if (item.conduta !== null) {
            fullText += `\nConduta: ${item.conduta}.`;
          }
          if (item.exame_fisico !== null) {
            fullText += `\nExame Físico: ${item.exame_fisico}.`;
          }
          fullText += `\nObjetivo Esperado: ${item.texto_meta}. `;

          let evolution = doc.getTextWidth(`Evolução: ${item.texto_evolucao}. Diagnóstico Clínico: ${item.diagnostico_clinico}. Conduta: ${item.conduta}. Exame Físico: ${item.exame_fisico}. Objetivo Esperado: ${item.texto_meta}.`)
          let splittedText = doc.splitTextToSize(fullText, maxWidth);

          if (evolution < maxWidth) {
            doc.setFont("helvetica", "bold");
            doc.text("Evolução: ", xFixedPosition, yPosition);
            doc.setFont("helvetica", "normal");
            doc.text(`${item.texto_evolucao}.`, xFixedPosition + lineWidth + doc.getTextWidth("Evolução: "), yPosition);

            // Adicionar Diagnóstico Clínico apenas se estiver presente
            if (item.diagnostico_clinico !== null && item.diagnostico_clinico !== undefined) {
              doc.text(`\nDiagnóstico Clínico: ${item.diagnostico_clinico}. `, xFixedPosition + lineWidth + doc.getTextWidth("Evolução: "), yPosition);
            }
            if (item.conduta !== null && item.conduta !== undefined) {
              doc.text(`Conduta: ${item.conduta}. `, xFixedPosition + lineWidth + doc.getTextWidth("Evolução: "), yPosition);
            }
            if (item.exame_fisico !== null && item.exame_fisico !== undefined) {
              doc.text(`\nExame Físico: ${item.exame_fisico} .`, xFixedPosition + lineWidth + doc.getTextWidth("Evolução: "), yPosition);
            }

            yPosition += lineHeight;
            yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);

            doc.setFont("helvetica", "bold");
            doc.text("Objetivo Esperado: ", xFixedPosition, yPosition);
            doc.setFont("helvetica", "normal");
            doc.text(` ${item.texto_meta}.`, xFixedPosition + lineWidth + doc.getTextWidth("Objetivo Esperado: "), yPosition);

            yPosition += lineHeight;
          } else {
            splittedText.forEach((line) => {
              yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, initialPosition);

              let currentX = xFixedPosition;

              nonVariableParts.forEach((part) => {
                if (line.includes(part)) {
                  doc.setFont("helvetica", "bold");
                  doc.text(part, currentX, yPosition);
                  currentX += doc.getTextWidth(part);
                  line = line.replace(part, "");
                }
              });

              variableParts.forEach((part) => {
                if (line.includes(part)) {
                  doc.setFont("helvetica", "normal");
                  doc.text(part, currentX, yPosition);
                  currentX += doc.getTextWidth(part);
                  line = line.replace(part, "");
                }
              });

              if (line.trim() !== "") {
                doc.setFont("helvetica", "normal");
                doc.text(line, currentX, yPosition);
              }

              yPosition += lineHeight;
            })
          }

          if (item.profissional && item.profissional.carimbo) {
            let carimbo = item.profissional.carimbo

            const params = item.profissional.carimbo.split('://')
            if (params[0] === 'http' && params[1] !== 'localhost') {
              carimbo = carimbo.replace('http', 'https')
            }

            try {
              doc.setFont("helvetica", "bold");
              doc.text("Carimbo: ", xFixedPosition, yPosition);
              doc.setFont("helvetica", "normal");
              yPosition += lineHeight;

              doc.addImage(
                carimbo,
                "PNG" || "JPEG",
                xFixedPosition,
                yPosition,
                50,
                20,
              );

              const imgHeight = 15;
              yPosition += imgHeight + lineHeight;
            } catch (error) {
              this.$toast.error("Erro ao carregar a imagem do carimbo (L-565):", error);
            }

            if (includeAttachments && Array.isArray(item?.arquivos) && item.arquivos.length > 0) {
              for (let i = 0; i < item.arquivos.length; i++) {
                const BASE_URL = UtilsService.urlApi();
                const arquivoUrl = UtilsPDF.joinURL(BASE_URL, item.arquivos[i].arquivo);

                if (arquivoUrl) {
                  const anexoImgHeight = 90;
                  if (yPosition + anexoImgHeight + lineHeight > 300) {
                    doc.addPage();
                    this.drawHeader(doc);
                    yPosition = 86;
                  }

                  doc.text(`Anexo ${i + 1}:`, xFixedPosition, yPosition);
                  yPosition += lineHeight;

                  try {
                    doc.addImage(arquivoUrl, "PNG" || "JPEG", xFixedPosition, yPosition, 80, 80);
                  } catch (error) {
                    this.$toast.error("Erro ao carregar a imagem do anexo (L-587):", error);
                  }
                  yPosition += anexoImgHeight + lineHeight;
                }
              }
            }
          } else {
            yPosition += lineHeight;
          }
        }
      }

      // Exclui uma página em branco no fim do arquivo, se houver
      if (!this.contentAdded && doc.internal.getNumberOfPages() > 1) {
        doc.deletePage(doc.internal.getNumberOfPages());
      }
      // doc.addPage();
      this.drawHeader(doc);

      UtilsPDF.addPageNumbers(doc);
      this.doc = doc
    },
    checkAndAddPage(doc, yPosition, lineHeight, maxHeight, newPosition) {
      if (yPosition + lineHeight > maxHeight) {
        doc.addPage();
        this.drawHeader(doc);
        yPosition = newPosition
        return yPosition;  // retorna o novo yPosition
      } else {
        this.contentAdded = true;
        return yPosition;  // retorna o yPosition inalterado
      }
    },
    async getQuadroClinico() {
      if (this.filteredData[0] && this.filteredData[0].paciente && this.filteredData[0].paciente.id) {
        try {
          const { data } = await api.get(`atendimentos/app-texto-quadroclinico/?paciente=${this.filteredData[0].paciente.id}`);
          this.quadroclinico = data[0]
          // console.log('getQuadroClinico -> ', this.quadroclinico)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Quadro Clínico (L-627):", error);
        }
      }
    },
  },
};
</script>